<template>
    <v-card  height="100%">
        <DlgBannerAdd ref="dlgCommon" :visible="visible" @close="dlgClose"></DlgBannerAdd>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgMsgClose"></DlgMessage>
        <div class="pa-3">
          <v-toolbar>
            <v-toolbar-title>Banner</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div>
        <div class="pa-3">
        <v-card>
        <v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon dark class="mb-2 success" @click="addRecord">
            <v-icon>mdi-plus</v-icon>
        </v-btn>
        </v-card-title>
        <v-card-text>

        <v-text-field
            v-model="tblSearch"
            append-icon="mdi-magnify"
            label="Search data below"
            hide-details
            outlined
        ></v-text-field>
        </v-card-text>

        <v-data-table
        v-model="selected"  
        ref="vuetable"
        item-key="created_date"
        :headers="tblHeaders"
        :items="tblBody"
        :search="tblSearch"
        :loading = "loadingFlag"
        loading-text="Loading... Please wait"    
        @click:row="openDetail"
        ></v-data-table>
        </v-card>
        </div>

    </v-card>

</template>
<script>
import ApMarketingService from '@/services/ApMarketingService'
import DlgBannerAdd from "@/components/DlgBannerAdd";
import DlgMessage from '@/components/DlgMessage'
export default {
    components:{DlgBannerAdd,DlgMessage},
    data() {
        return{
            //breadcrumb
            items: [
              {text:'Marketing',disabled: false,href:'#',},
              {text:'Banner',disabled: false,href:'#',},
              {text:'List',disabled: false,href:'#',},
              ],
            selected: [],
            tblHeaders:[
                { text:"Code",value: "mb_code"  },
                { text:"Name", value:"mb_name" },
                { text:"Sequence",value: "sequence"  },                
                { text:"Start Date", value:"start_date" },
                { text:"End Date", value:"end_date" },
                { text:"Status", value:"status" },

            ],
            tblBody:[],
            tblSearch:'',
            loadingFlag : false,
            visible:false,

        }
    },
    async created() {
      this.refreshDataTable();
    },
    methods:{
            async refreshDataTable(){
                this.loadingFlag = true
                let title = 'something';
                try{
                    ApMarketingService.list_marketing_banner(this.form).then((res) => {
                        this.tblBody = res.data.data;
                        this.loadingFlag = false;
                    }).catch((e)=>{
                            //console.log(e.response);
                        if(e.response.data.error_message)
                            this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response.data.error_message);
                        this.loadingFlag = false;
                    })
                        ;
                } catch(e) {
                    if(e)
                        this.$refs.dlgMsg.showDlgBox(true,'error',title,'Unknown Error');
                    this.loadingFlag = false;
                } 
      
            },
            openDetail(value){
                console.log(value)
                this.$router.push({ name: 'marketing-banner-details', params: { marketing_banner_id: value.marketing_banner_id } })
                //this.$router.push("/merchants/detail/" + value.merchant_id);
            },

            addRecord(){
                this.visible = true;
            },
            dlgClose(){
                this.visible = false;
                this.refreshDataTable();
            },

            dlgMsgClose(){
            },

    }

}
</script>