<template>
    <v-dialog max-width="500px" v-model="show">
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <v-card>
            <v-toolbar flat dark color="panel1">
                <v-toolbar-title>New Banner</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="show=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <div class="pa-5">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-form ref="mainForm">
                            <v-text-field 
                                label="Code" 
                                outlined
                                dense
                                v-model="form.mb_code"
                                v-if="show" autofocus  
                            ></v-text-field>
                            <v-text-field 
                                label="Name" 
                                outlined
                                dense
                                v-model="form.mb_name"  
                            ></v-text-field>
                            <v-text-field 
                                label="Sequence No." 
                                outlined
                                dense
                                v-model="form.sequence"  
                            ></v-text-field>
                            <v-menu v-model="dpicker.menu1" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        :value="form.start_date"
                                        label="Start Date"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="form.start_date" @change="dpicker.menu1 = false"></v-date-picker>
                            </v-menu>
                            <v-menu v-model="dpicker.menu2" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        :value="form.end_date"
                                        label="End Date"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="form.end_date" @change="dpicker.menu2 = false"></v-date-picker>
                            </v-menu>

                            
                        </v-form>
                    </v-col>
                </v-row>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="cbtnSave" dark @click="save">
                    <v-icon left dark>mdi-check</v-icon>
                    Submit
                </v-btn>
                <v-btn class="ml-2" color="cbtnRed" dark @click="show=false">
                    <v-icon left dark>mdi-close</v-icon>
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import DlgMessage from '@/components/DlgMessage'
import ApMarketingService from "@/services/ApMarketingService"
import moment from 'moment'
export default {
    name: "DlgBannerAdd",
    props: {
        visible:Boolean,
    },      
    components:{DlgMessage},
    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
                this.$emit('close')
           }
        }
      },
      
    },      
    data() {
    return {
        
      message: "",
      customer_id:0,
      form:{
            
            login_id:"",
            sequence:0,
            mb_code:"",
            mb_name:"",
            image_file_name:"",
            start_date:"",
            end_date:"",
            status:"ACTIVE",
            
          },
        dataList:[],
        dpicker:{menu1: false,menu2: false,},
        respCode:0,
    //   rules: {
    //         required: value => !!value || 'Required.',
    //         counter: value => value.length == 6 || 'Need 6 digit',
    //         digit: value=> /[0-9][0-9][0-9][0-9][0-9][0-9]/.test(value) || 'Number only',
    //   },
    };
  },
  async created(){
      this.form.start_date = moment(String(new Date())).format('YYYY-MM-DD');
      this.form.end_date = moment(String(new Date())).format('YYYY-MM-DD');
  },
  mounted(){
        this.form.start_date = moment(String(new Date())).format('YYYY-MM-DD');
        this.form.end_date = moment(String(new Date())).format('YYYY-MM-DD');

  },
  methods: {
    dlgShow(){
    },
    dlgClose(){
        console.log(this.respCode);
        
        if(this.respCode == 200){
            this.form.mb_code = "";
            this.form.mb_name = "";
            this.form.sequence = 0;
            this.show = false;
        }
            
    },
    async save(){
        if(this.$refs.mainForm.validate()) {
            let title = "New Banner"
            try{
                ApMarketingService.insert_marketing_banner(this.form).then((res) => {
                    //console.log(res);
                    this.respCode = res.status;
                    this.$refs.dlgMsg.showDlgBox(true,'success',title,'Success.');
                }).catch((e)=>{
                    //console.log(e.response);
                    this.respCode = e.response.status;
                    if(e.response.data.error_message)
                        this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response.data.error_message);
                })
                ;
            } catch(e) {
                if(e)
                    this.$refs.dlgMsg.showDlgBox(true,'error',title,'Unknown Error');
            }
        }
    },

  }
};

</script>
